.App {
  min-height: 100vh;
}

.back-color1 {
  background-color: #051E4A;
}

.color1 {
  color: #051E4A;
}
